<template>
    <div>
        <el-dialog title="Confirm order" width="500px" top="10vh" :visible.sync="optionsOrderShow" :close-on-click-modal="false">
            <el-row :gutter="20" type="flex" class="text-center font-bold">
                <el-col :span="24">
                    <p class="mt-0 mb-10">Current Price</p>
                    <h1 v-if="orderFormData.type" class="m-0 color-d" v-text="info.close" />
                    <h1 v-else class="m-0 color-s" v-text="info.close" />
                </el-col>
                <!-- <el-col :span="12">
                    <p class="mt-0">Direction</p>
                    <h2 v-if="orderFormData.type" class="mt-0 color-d">Buy Down</h2>
                    <h2 v-else class="m-0 color-s">Buy Up</h2>
                </el-col> -->
            </el-row>
            <el-divider />
            <div v-if="orderFormData.id">
                <div class="text-center my-30">
                    <el-progress v-if="orderFormData.status == 0" type="circle" :percentage="currentRate" :stroke-width="30" :format="formatOrderProgress" />
                    <h1 v-else-if="orderFormData.status == 1" class="my-40 font-32">+{{ Number(orderFormData.profit) }} USDT</h1>
                    <h1 v-else class="my-40 font-32">-{{ Number(orderFormData.amount) }} USDT</h1>
                </div>
                <el-divider />
                <el-row :gutter="20" type="flex" class="text-center">
                    <el-col :span="8">
                        <p class="mt-0 mb-10">Due Time</p>
                        <h3 class="m-0">{{ orderFormData.due_sec }}s</h3>
                    </el-col>
                    <el-col :span="8">
                        <p class="mt-0">Profit</p>
                        <h3 class="m-0">{{ orderFormData.due_sec ? dueTimeList[orderFormData.due_sec].profit_rate * 1 : 0 }}%</h3>
                    </el-col>
                    <el-col :span="8">
                        <p class="mt-0">Amount</p>
                        <h3 class="m-0">{{ orderFormData.amount }} USDT</h3>
                    </el-col>
                </el-row>
                <el-divider />
                <div class="text-center">
                    <el-button type="info" icon="el-icon-close" @click="onOptionsOrderHide()">Close</el-button>
                    <el-button type="warning" icon="el-icon-back" @click="onOptionsOrderShow()">Order Again</el-button>
                </div>
            </div>
            <el-form
                v-else
                ref="optionsOrderForm"
                v-loading="orderLoading"
                element-loading-background="#ffffff88"
                class="white-form"
                :model="orderFormData"
                label-position="top"
                :show-message="false"
                :status-icon="true"
                :rules="rules"
            >
                <div class="d-flex-center py-16">
                    <div class="font-bold">Due Time</div>
                </div>
                <el-radio-group v-model="orderFormData.due_sec" size="large" class="d-flex-center mb-16 font-bold">
                    <el-radio-button v-for="(item, index) in dueTimeList" class="" :key="index" :label="index" border>
                        <div class="font-18" style="width: 50px">{{ index }}s</div>
                        <div class="mt-5 mb-5 font-12 color-666">Profit</div>
                        <div>{{ item.profit_rate * 1 }}%</div>
                    </el-radio-button>
                </el-radio-group>
                <div class="d-flex-center py-16">
                    <div class="mr-auto font-bold">Amount</div>
                    <div class="font-14">
                        <span>{{ $t('available') }}</span>
                        <span class="mx-3">:</span>
                        <span v-text="userInfo.coin" />
                        <span class="ml-3">USDT</span>
                    </div>
                </div>
                <el-form-item prop="amount" class="m-0">
                    <el-input v-model="orderFormData.amount" clearable size="large" type="number" prefix-icon="el-icon-money" autocomplete="off"  placeholder="Enter Investment Amount" @mousewheel.native.prevent>
                        <template slot="append">USDT</template>
                    </el-input>
                </el-form-item>
                <el-radio-group v-model="orderFormData.amount" size="small" class="d-flex-center py-16">
                    <el-radio-button label="50">50</el-radio-button>
                    <el-radio-button label="100">100</el-radio-button>
                    <el-radio-button label="300">300</el-radio-button>
                    <el-radio-button label="500">500</el-radio-button>
                    <el-radio-button label="1000">1000</el-radio-button>
                    <el-radio-button label="2000">2000</el-radio-button>
                    <el-radio-button label="3000">3000</el-radio-button>
                    <el-radio-button label="5000">5000</el-radio-button>
                    <el-radio-button label="10000">10000</el-radio-button>
                </el-radio-group>
                <div class="font-14 text-center font-bold mb-20">
                    <span>Expected Profits</span>
                    <span class="mx-5">:</span>
                    <span class="color-danger" v-text="expectedProfits" />
                    <span class="ml-3">USDT</span>
                </div>
                <div class="text-center">
                    <el-button type="info" icon="el-icon-close" @click="onOptionsOrderHide()">Cancel</el-button>
                    <el-button type="primary" icon="el-icon-s-promotion" @click="submitOptionsOrderForm()">Submit</el-button>
                </div>
            </el-form>
        </el-dialog>
        <el-drawer title="Market" direction="ltr" :size="360" :visible.sync="showMarket">
            <div class="bg-white px-20">
                <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 font-12">
                    <el-col :span='8'>Pair</el-col>
                    <el-col :span='10'>Price</el-col>
                    <el-col class="text-right" :span='6'>Change</el-col>
                </el-row>
                <div v-for="(item, index) in market" :key="index" class="is-link" @click="$nav.replace('/market/'+item.pair)">
                    <el-row :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-top">
                        <el-col :span='3'>
                            <i v-if="item.logo" class="icon icon-stars" :style="{ backgroundImage: 'url('+item.logo+')' }" />
                            <i v-else class="icon icon-stars" :class="'icon-coin-'+item.pair.toLowerCase()" />
                        </el-col>
                        <el-col :span='5' class="font-14">{{ item.pair }}</el-col>
                        <el-col :span='10' class="font-14">{{ item.lasest_price }}</el-col>
                        <el-col :span='6' class="text-right">
                            <el-tag v-if="item.change > 0" class="change" type="success" effect="dark" size="small">+{{ item.change.toFixed(2) }}%</el-tag>
                            <el-tag v-else-if="item.change == 0" class="change" type="warning" effect="dark" size="small">0.00%</el-tag>
                            <el-tag v-else class="change" type="danger" effect="dark" size="small">{{ item.change.toFixed(2) }}%</el-tag>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-drawer>
        <div class="content-box mb-50 mt-20">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flex-center top-info font-bold bg-white border border-r p-20 mb-20">
                        <div class="mr-auto pair-coin my-0 d-flex-center is-link" @click="showMarket = true">
                            <div class="icon icon-indent mr-10"></div>
                            <div>{{ $route.params.pair }} / USDT</div>
                        </div>
                        <div class="price ml-20 mr-40">
                            <div class="font-18 price-value" :class="{ 'color-s': info.change > 0, 'color-d': info.change < 0 }">{{ info.close }}</div>
                            <div class="mt-3 color-6 font-14">Latest {{ $t('home.price') }}</div>
                        </div>
                        <div class="change text-center mr-40">
                            <div v-if="info.change > 0" class="font-18 color-s">+{{ info.change }}%</div>
                            <div v-else-if="info.change == 0" class="font-18">{{ info.change }}%</div>
                            <div v-else class="font-18 color-d">{{ info.change }}%</div>
                            <div class="mt-3 color-6 font-14">24H {{ $t('home.change') }}</div>
                        </div>
                        <div class="high text-center mr-40">
                            <div class="font-18">{{ info.high }}</div>
                            <div class="mt-3 color-6 font-14">24H {{ $t('home.high') }}</div>
                        </div>
                        <div class="low text-center mr-40">
                            <div class="font-18">{{ info.low }}</div>
                            <div class="mt-3 color-6 font-14">24H {{ $t('home.low') }}</div>
                        </div>
                        <div class="finished text-right">
                            <div class="font-18">{{ Number(info.vol).toFixed(2) }}</div>
                            <div class="mt-3 color-6 font-14">24H {{ $t('home.finished') }}</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="bg-white border border-r" :style="tabStyle[activeTabs].mbpStyle">
                        <div class="px-20 py-15 border-bottom">
                            Order Book
                        </div>
                        <el-row :gutter="20" type="flex" justify="center" align="middle" class="border-bottom px-20 py-10 font-12">
                            <el-col :span='6'>Position</el-col>
                            <el-col :span='9'>Price</el-col>
                            <el-col class="text-right" :span='9'>Amount</el-col>
                        </el-row>
                        <div v-if="mbp.asks.length == 10" class="mbp-sell color-s">
                            <div v-for="index in tabStyle[activeTabs].mbpAsksRand" :key="index" @click="onSetPrice(mbp.asks[index][0])" class="is-link">
                                <el-row :gutter="20" type="flex" justify="center" align="middle" class="border-bottom font-14 px-20 py-10">
                                    <el-col :span='6' class="font-14">Sell{{ index+1 }}</el-col>
                                    <el-col :span='9' class="font-14">{{ mbp.asks[index][0] }}</el-col>
                                    <el-col :span='9' class=" text-right">{{ mbp.asks[index][1].toFixed(8) * 1 }}</el-col>
                                </el-row>
                            </div>
                        </div>
                        <div class="p-20 d-flex-center is-link" @click="onSetPrice(info.close)">
                            <div v-if="info.up" class="font-18 font-bold mr-auto color-s"><i class="el-icon-top"></i> {{ info.close }}</div>
                            <div v-else class="font-18 font-bold mr-auto color-d"><i class="el-icon-bottom"></i> {{ info.close }}</div>
                            <!-- <div class="font-18 font-bold mr-auto" :class="{ 'color-s': info.up === true, 'color-d': info.up === false }">{{ info.close }} <i class="el-icon-share"></i></div> -->
                            <div class="ml-10 font-14 color-666">≈ ${{ Number(info.close).toPrecision(6) }}</div>
                        </div>
                        <div v-if="mbp.bids.length == 10" class="mbp-buy color-d">
                            <div v-for="index in tabStyle[activeTabs].mbpBidsRand" :key="index" @click="onSetPrice(mbp.bids[index][0])" class="is-link">
                                <el-row :gutter="20" type="flex" justify="center" align="middle" class="border-bottom font-14 px-20 py-10">
                                    <el-col :span='6' class="font-14">Buy{{ index+1 }}</el-col>
                                    <el-col :span='9' class="font-14">{{ mbp.bids[index][0] }}</el-col>
                                    <el-col :span='9' class=" text-right">{{ mbp.bids[index][1].toFixed(8) * 1 }}</el-col>
                                </el-row>
                            </div>
                        </div>
                        <div class="p-20">
                            <el-progress :percentage="mbp.asks_rate" :show-text="false" status="success" define-back-color="#ee0a24" />
                        </div>
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="kline-box bg-white border border-r p-20">
                        <div class="kline-tools font-bold">
                            <el-button :type="Period == 0 ? 'success' : 'primary'" size="mini" plain class="mx-0 py-5 font-12" @click="ChangePeriod(0)">1Day</el-button>
                            <el-button :type="Period == 7 ? 'success' : 'primary'" size="mini" plain class="mx-0 py-5 font-12" @click="ChangePeriod(7)">30Min</el-button>
                            <el-button :type="Period == 6 ? 'success' : 'primary'" size="mini" plain class="mx-0 py-5 font-12" @click="ChangePeriod(6)">15Min</el-button>
                            <el-button :type="Period == 5 ? 'success' : 'primary'" size="mini" plain class="mx-0 py-5 font-12" @click="ChangePeriod(5)">5Min</el-button>
                            <el-button :type="Period == 4 ? 'success' : 'primary'" size="mini" plain class="mx-0 py-5 font-12" @click="ChangePeriod(4)">1Min</el-button>
                        </div>
                        <div class="kline-div" ref="kline" style="height: 480px; width: 853px; position：relative" />
                    </div>
                    <div class="mt-20 px-20 pt-10 border border-r bg-white" :style="tabStyle[activeTabs].paneStyle">
                        <el-tabs v-model="activeTabs" @tab-click="onChangeTabs">
                            <el-tab-pane label="Spot" name="spot" class="py-20">
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <div class="pr-20 border-right">
                                            <el-form
                                                ref="buyForm"
                                                v-loading="buyLoading"
                                                class="input-form mx-auto"
                                                :model="buyFormData"
                                                :show-message="false"
                                                :status-icon="true"
                                                :rules="rules"
                                            >
                                                <div class="mb-20 d-flex-center font-14 font-bold color-666">
                                                    <span class="mr-auto">{{ $t('available') }} USDT : </span>
                                                    <span>{{ userInfo.coin }}</span>
                                                </div>
                                                <el-form-item prop="price">
                                                    <el-input v-model="buyFormData.price" type="number" prefix-icon="el-icon-money" :placeholder="$t('price')">
                                                        <span class="color-333" slot="append">USDT</span>
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item prop="amount">
                                                    <el-input v-model="buyFormData.amount" type="number" prefix-icon="el-icon-coin" :placeholder="$t('amount')">
                                                        <span class="color-333" slot="append">{{ $route.params.pair }}</span>
                                                    </el-input>
                                                    
                                                </el-form-item>
                                                <!-- <el-form-item>
                                                    <div class="mx-10">
                                                        <el-slider v-model="buyFormData.slider"></el-slider>
                                                    </div>
                                                </el-form-item> -->
                                                <el-form-item>
                                                    <el-input :value="buyTotalValue" readonly prefix-icon="el-icon-money" :placeholder="$t('total')">
                                                        <span class="color-333" slot="append">USDT</span>
                                                    </el-input>
                                                </el-form-item>
                                                <div>
                                                    <el-button type="success" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitBuyForm()">Buy</el-button>
                                                </div>
                                            </el-form>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="pl-20 border-left">
                                            <el-form
                                                ref="sellForm"
                                                v-loading="sellLoading"
                                                class="input-form mx-auto"
                                                :model="sellFormData"
                                                :show-message="false"
                                                :status-icon="true"
                                                :rules="rules"
                                            >
                                                <div class="mb-20 d-flex-center font-14 font-bold color-666">
                                                    <span class="mr-auto">{{ $t('available') }} {{ $route.params.pair }} : </span>
                                                    <span>{{ userInfo.pair }}</span>
                                                </div>
                                                <el-form-item prop="price">
                                                    <el-input v-model="sellFormData.price" type="number" prefix-icon="el-icon-money" :placeholder="$t('price')">
                                                        <span class="color-333" slot="append">USDT</span>
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item prop="amount">
                                                    <el-input v-model="sellFormData.amount" type="number" prefix-icon="el-icon-coin" :placeholder="$t('amount')">
                                                        <span class="color-333" slot="append">{{ $route.params.pair }}</span>
                                                    </el-input>
                                                </el-form-item>
                                                <!-- <el-form-item>
                                                    <div class="mx-10">
                                                        <el-slider v-model="sellFormData.slider"></el-slider>
                                                    </div>
                                                </el-form-item> -->
                                                <el-form-item>
                                                    <el-input :value="sellTotalValue" readonly prefix-icon="el-icon-money" :placeholder="$t('total')">
                                                        <span class="color-333" slot="append">USDT</span>
                                                    </el-input>
                                                </el-form-item>
                                                <div>
                                                    <el-button type="danger" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitSellForm()">Sell</el-button>
                                                </div>
                                            </el-form>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Options" name="options" class="py-20">
                                <div class="mb-20 d-flex-center font-14 font-bold color-666">
                                    <span class="mr-auto">{{ $t('long') }} / {{  $t('short') }}</span>
                                    <span class="mr-10">{{ $t('available') }} USDT : </span>
                                    <span>{{ userInfo.coin }}</span>
                                </div>
                                <el-form
                                    ref="sellForm"
                                    class="input-form mx-auto"
                                    :show-message="false"
                                    :status-icon="true"
                                >
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <el-button type="success" icon="el-icon-top" class="w-100" native-type="button" @click="onOptionsOrderShow(0)">{{ $t('long') }}</el-button>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-button type="danger" icon="el-icon-bottom" class="w-100" native-type="button" @click="onOptionsOrderShow(1)">{{ $t('short') }}</el-button>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </el-tab-pane>
                            <el-dialog  title="Margin Mode" width="360px" :visible.sync="showMarginModeSetting" :close-on-click-modal="true">
                                <div v-loading="usdsmSettingLoading">
                                    <el-link :underline="false" :type="usdsmInfo.margin_mode === 0 ? 'primary' : 'default'" class="font-bold mb-8 btn btn-info-outline p-16" @click="marginModeChange(0)">
                                        <div class="font-16 line-height-16 mb-5">
                                            <span>Cross</span>
                                        </div>
                                        <div class="font-12 line-height-14">All cross positions under the same margin asset share the same asset cross margin balance. In the event of liquidation, your assets full margin balance along with any remaining open positions under the asset may be forfeited.</div>
                                    </el-link>
                                    <el-link :underline="false" :type="usdsmInfo.margin_mode === 1 ? 'primary' : 'default'" class="font-bold mb-8 btn btn-info-outline p-16" @click="marginModeChange(1)">
                                        <div class="font-16 line-height-16 mb-5">
                                            <span>Isolated</span>
                                        </div>
                                        <div class="font-12 line-height-14">The Margin assigned to a position is restricted to a certain amount. If the Margin falls below the Maintenance Margin level, the position is liquidated. However, you can add and remove Margin at will under this mode.</div>
                                    </el-link>
                                    <div class="font-14 font-bold color-gray my-8">
                                        <span>* Switching of margin modes only applies to the selected contract.</span>
                                    </div>
                                </div>
                            </el-dialog>
                            <el-dialog  title="Adjust Leverage" width="360px" :visible.sync="showAdjutLeverageSetting" :close-on-click-modal="true">
                                <div v-loading="usdsmSettingLoading">
                                    <div class="d-flex-center mb-20">
                                        <el-input-number v-model="leverage" :min="1" :max="usdsmInfo.leverage_max"></el-input-number>
                                    </div>
                                    <div class="font-12 font-bold color-gray mt-8">
                                        <span>* Please note that leverage changing will also apply for open positions anc open orders.</span>
                                    </div>
                                    <div class="font-12 font-bold color-gray mt-8">
                                        <span>* Selecting higher leverage such as[10x] increases your liquidation risk.</span>
                                    </div>
                                    <div class="font-12 font-bold color-gray mt-8">
                                        <span>* Always manage your risk levels. See our help article for more information.</span>
                                    </div>
                                    <div class="d-flex-center mt-20">
                                        <el-button type="primary" @click="adjustLerverageInput()">Confirm</el-button>
                                    </div>
                                </div>
                            </el-dialog>
                            <el-tab-pane label="USD(S)-Margin" name="usdsm" class="pb-20 pt-5">
                                <div class="d-flex-center font-14 font-bold color-666 mb-20">
                                    <span>{{ $t('available') }}</span>
                                    <span class="mx-5">:</span>
                                    <span class="mr-auto">{{ usdsmInfo.usdt_available }} USDT</span>
                                    <el-button-group>
                                        <el-button type=" " size="mini" @click="showMarginModeSetting = true">
                                            <span v-if="usdsmInfo.margin_mode">Isolated</span>
                                            <span v-else>Cross</span>
                                            <i class="el-icon-arrow-down el-icon--right"></i>
                                        </el-button>
                                        <el-button type=" " size="mini" @click="adjustLeverageOpen()">
                                            <span>{{ usdsmInfo.leverage }}X</span>
                                            <i class="el-icon-arrow-down el-icon--right"></i>
                                        </el-button>
                                    </el-button-group>
                                </div>
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <div class="pr-20 border-right">
                                            <el-form
                                                ref="usdsmOpenForm"
                                                v-loading="buyLoading"
                                                class="input-form mx-auto"
                                                :model="usdsmOpenFormData"
                                                :show-message="false"
                                                :status-icon="true"
                                                :rules="rules"
                                            >
                                                <el-form-item v-if="usdsmOpenFormData.price_type">
                                                    <el-input v-model="usdsmOpenFormData.price" type="number" prefix-icon="el-icon-money" :placeholder="$t('price')">
                                                        <el-select v-model="usdsmOpenFormData.price_type" slot="append" placeholder="请选择" :style="{ width: '150px' }">
                                                            <el-option label="Market Price" :value="0" />
                                                            <el-option label="Limit Price" :value="1" />
                                                        </el-select>
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item v-else>
                                                    <el-select v-model="usdsmOpenFormData.price_type" placeholder="请选择" :style="{ width: '100%' }">
                                                        <el-option label="Market Price" :value="0" />
                                                        <el-option label="Limit Price" :value="1" />
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item prop="amount">
                                                    <el-input v-model="usdsmOpenFormData.amount" type="number" prefix-icon="el-icon-coin" :placeholder="$t('amount')">
                                                        <span class="color-333" slot="append">{{ $route.params.pair }}</span>
                                                    </el-input>
                                                    
                                                </el-form-item>
                                                <div class="mb-15 d-flex-center font-14 font-bold color-666">
                                                    <span class="mr-auto">{{ $t('MAX') }} Open : </span>
                                                    <span>{{ maxOpen }} {{ $route.params.pair }}</span>
                                                </div>
                                                <div class="mb-20 mb-15">
                                                    <el-button type="success" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="usdsmSubmitOpenForm(0)">Open Long</el-button>
                                                </div>
                                                <div class="mb-15 d-flex-center font-14 font-bold color-666">
                                                    <span class="mr-auto">{{ $t('MAX') }} Open : </span>
                                                    <span>{{ maxOpen }} {{ $route.params.pair }}</span>
                                                </div>
                                                <div>
                                                    <el-button type="danger" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="usdsmSubmitOpenForm(1)">Open Short</el-button>
                                                </div>
                                            </el-form>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="pl-20 border-left">
                                            <el-form
                                                ref="usdsmCloseForm"
                                                v-loading="sellLoading"
                                                class="input-form mx-auto"
                                                :model="usdsmCloseFormData"
                                                :show-message="false"
                                                :status-icon="true"
                                                :rules="rules"
                                            >
                                                <el-form-item v-if="usdsmCloseFormData.price_type">
                                                    <el-input v-model="usdsmCloseFormData.price" type="number" prefix-icon="el-icon-money" :placeholder="$t('price')">
                                                        <el-select v-model="usdsmCloseFormData.price_type" slot="append" placeholder="请选择" :style="{ width: '150px' }">
                                                            <el-option label="Market Price" :value="0" />
                                                            <el-option label="Limit Price" :value="1" />
                                                        </el-select>
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item v-else>
                                                    <el-select v-model="usdsmCloseFormData.price_type" placeholder="请选择" :style="{ width: '100%' }">
                                                        <el-option label="Market Price" :value="0" />
                                                        <el-option label="Limit Price" :value="1" />
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item prop="amount">
                                                    <el-input v-model="usdsmCloseFormData.amount" type="number" prefix-icon="el-icon-coin" :placeholder="$t('amount')">
                                                        <span class="color-333" slot="append">{{ $route.params.pair }}</span>
                                                    </el-input>
                                                </el-form-item>
                                                <div class="mb-15 d-flex-center font-14 font-bold color-666">
                                                    <span class="mr-auto">Max Close : </span>
                                                    <span>{{ usdsmInfo.pair_long_balance }} {{ $route.params.pair }}</span>
                                                </div>
                                                <div class="mb-20">
                                                    <el-button type="danger" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="usdsmSubmitCloseForm(0)">Close Long</el-button>
                                                </div>
                                                <div class="mb-15 d-flex-center font-14 font-bold color-666">
                                                    <span class="mr-auto">Max Close : </span>
                                                    <span>{{ usdsmInfo.pair_short_balance }} {{ $route.params.pair }}</span>
                                                </div>
                                                <div>
                                                    <el-button type="success" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="usdsmSubmitCloseForm(1)">Close Short</el-button>
                                                </div>
                                            </el-form>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-col>
            </el-row>
            <div v-show="activeTabs === 'spot'" class="my-20 bg-white border border-r px-20 pb-20" v-loading="tradingLoading">
                <div class="d-flex-center border-bottom">
                    <h4 class="mr-auto color-333">Open Order</h4>
                    <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchTradingList()">{{ $t('refresh') }}</el-button>
                    <el-button type="primary" size="mini" icon="el-icon-s-order" plain @click="$nav.push('/user/account/transactions')">{{ $t('all') }} >></el-button>
                </div>
                <el-row :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-12">
                    <el-col :span='4'>Type</el-col>
                    <el-col :span='5'>Price</el-col>
                    <el-col :span='5'>Amount</el-col>
                    <el-col :span='6'>Time</el-col>
                    <el-col class="text-right" :span='4'>Deal</el-col>
                </el-row>
                <div v-if="tradingList.length">
                    <el-row v-for="(item, index) in tradingList" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="px-20 py-15 border-bottom font-14">
                        <el-col :span='4'>
                            <el-tag v-if="item.type" type="info" effect="plain" hit class="bg-t">Sell</el-tag>
                            <el-tag v-else type="primary" effect="plain" hit class="bg-t">Buy</el-tag>
                        </el-col>
                        <el-col :span='5'>{{ item.price }}</el-col>
                        <el-col :span='5'>{{ item.amount }}</el-col>
                        <el-col :span='6'>{{ item.create_time }}</el-col>
                        <el-col class="text-right" :span='4'>
                            <el-button type="danger" size="small" plain class="bg-t" @click="onCancelTradingOrder(index)">Cancel</el-button>
                        </el-col>
                    </el-row>
                </div>
                <el-empty v-else :image-size="100" description="No Data"></el-empty>
            </div>
            <div v-show="activeTabs === 'options'" class="my-20 bg-white border border-r px-20 pb-20" v-loading="tradingLoading">
                <div class="d-flex-center border-bottom">
                    <h4 class="mr-auto color-333">Latest Orders</h4>
                    <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchOptionsOrders()">{{ $t('refresh') }}</el-button>
                    <el-button type="primary" size="mini" icon="el-icon-s-order" plain @click="$nav.push('/user/account/options')">{{ $t('all') }} >></el-button>
                </div>
                <el-row :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
                    <el-col :span='3'>No.</el-col>
                    <el-col :span='3'>{{ $t('long') }} / {{  $t('short') }}</el-col>
                    <el-col :span='3'>Symbol</el-col>
                    <el-col :span='4'>Open / Close Price</el-col>
                    <el-col :span='2'>Amount</el-col>
                    <el-col :span='3'>Due Time / Profit</el-col>
                    <el-col :span='2'>Status</el-col>
                    <el-col class="text-right" :span='4'>Open / Close Time</el-col>
                    <!-- <el-col :span='3'>Usdt Balance</el-col> -->
                    
                </el-row>
                <div v-if="optionsOrders.length">
                    <el-row v-for="(item, index) in optionsOrders" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-16">
                        <el-col :span='3' class="font-14">{{ item.order_id }}</el-col>
                        <el-col :span='3'>
                            <el-tag v-if="item.type" type="danger" size="medium" class="font-14" effect="plain" hit>{{ $t('short') }}</el-tag>
                            <el-tag v-else type="success" size="medium" effect="plain" class="font-14" hit>{{ $t('long') }}</el-tag>
                        </el-col>
                        <el-col :span='3'>{{ item.pair }} / {{ item.coin }}</el-col>
                        <el-col :span='4'>
                            <div class="font-14">{{ item.price }} / {{ item.end_price }}</div>
                        </el-col>
                        <el-col :span='2'>{{ item.amount }}</el-col>
                        <el-col :span='3'>{{ item.due_sec }}s / {{ item.profit_rate }}%</el-col>
                        <el-col :span='2'>
                            <el-tag v-if="item.status === 0" type="primary" size="medium" effect="plain" class="font-16" hit>Waiting</el-tag>
                            <el-tag v-else-if="item.status === 1" type="success" size="medium" effect="plain" class="font-16" hit>+{{ item.profit }}</el-tag>
                            <el-tag v-else-if="item.status === 2" type="warning" size="medium" effect="plain" class="font-16" hit>Draw</el-tag>
                            <el-tag v-else-if="item.status === 3" type="danger" size="medium" effect="plain" class="font-16" hit>-{{ item.amount }}</el-tag>
                        </el-col>
                        <el-col class="text-right" :span='4'>
                            <div class="font-14">{{ item.create_time }}</div>
                            <div class="font-14">{{ item.end_time }}</div>
                        </el-col>
                        <!-- <el-col :span='3'>{{ item.status ? item.end_usdt_balance : '----' }}</el-col> -->
                        
                    </el-row>
                </div>
                <el-empty v-else :image-size="100" description="No Data"></el-empty>
            </div>
            <el-dialog title="Close Postation" width="400px" :visible.sync="showCloseFrom" :close-on-click-modal="false">
                <el-form
                    ref="closeForm"
                    v-loading="sellLoading"
                    class="input-form mx-auto"
                    :model="closeFormData"
                    :show-message="false"
                    :status-icon="true"
                    :rules="rules"
                >
                    <div class="d-flex-center font-14 py-10 border-bottom">
                        <span class="mr-auto color-gray">Syboml</span>
                        <span class="mr-8">{{ closeFormData.pair }} / USDT</span>
                        <span class="color-gray font-12 mr-8">Perp</span>
                        <span v-if="closeFormData.way" class="mr-5 color-danger">Short</span>
                        <span v-else class="mr-5 color-success">Long</span>
                    </div>
                    <div class="d-flex-center font-14 py-10 border-bottom">
                        <span class="mr-auto color-gray">Margin Mode</span>
                        <span v-if="closeFormData.margin_mode" class="mr-3 color-danger">Isolated {{ closeFormData.leverage }}X</span>
                        <span v-else class="mr-3 color-success">Cross {{ closeFormData.leverage }}X</span>
                    </div>
                    <div class="d-flex-center font-14 py-10 border-bottom">
                        <span class="mr-auto color-gray">Size</span>
                        <span class="mr-5">{{ closeFormData.size }} {{ closeFormData.pair }}</span>
                    </div>
                    <div class="d-flex-center font-14 py-10 border-bottom">
                        <span class="mr-auto color-gray">Entry Price</span>
                        <span class="mr-5">{{ closeFormData.entry_price }}</span>
                    </div>
                    <div class="d-flex-center font-14 py-10 border-bottom">
                        <span class="mr-auto color-gray">Mark Price</span>
                        <span class="mr-5">{{ closeFormData.mark_price }}</span>
                    </div>
                    <el-form-item v-if="closeFormData.price_type">
                        <el-input v-model="closeFormData.price" type="number" prefix-icon="el-icon-money" :placeholder="$t('price')">
                            <el-select v-model="closeFormData.price_type" slot="append" placeholder="请选择" :style="{ width: '150px' }">
                                <el-option label="Market Price" :value="0" />
                                <el-option label="Limit Price" :value="1" />
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item v-else>
                        <el-select v-model="closeFormData.price_type" placeholder="请选择" :style="{ width: '100%' }">
                            <el-option label="Market Price" :value="0" />
                            <el-option label="Limit Price" :value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Close Size" prop="amount">
                        <el-input v-model="closeFormData.amount" type="number" prefix-icon="el-icon-coin" :placeholder="$t('amount')">
                            <span class="color-333" slot="append">{{ closeFormData.pair }}</span>
                        </el-input>
                    </el-form-item>
                    <div class="d-flex-center font-14 py-10 mb-20">
                        <span class="mr-auto color-gray">Estimated PNL</span>
                        <span v-if="closeFormData.pnl >= 0" class="mr-5 color-success">+{{ closeFormData.pnl }} USDT</span>
                        <span v-else class="mr-5 color-danger">{{ closeFormData.pnl }} USDT</span>
                    </div>
                    <div class="text-center">
                        <el-button type="info" icon="el-icon-close" @click="showCloseFrom = false">Cancel</el-button>
                        <el-button type="primary" icon="el-icon-s-promotion" @click="usdsmOnSubmitClose()">Close</el-button>
                    </div>
                </el-form>
            </el-dialog>
            <div v-show="activeTabs === 'usdsm'" class="my-20 bg-white border border-r">
                <el-menu :default-active="usdmsActiveTabs" mode="horizontal" @select="usdsmChangeTabs" class="mx-0 px-0 d-flex-center">
                    <el-menu-item index="openorders" class="px-0 ml-20">Open Orders ({{ openOrderList.length }})</el-menu-item>
                    <el-menu-item index="positions" class="px-0 ml-20 mr-auto">Positions ({{ positions.length }})</el-menu-item>
                    <el-link type="primary" class="mx-20" icon="el-icon-s-order" @click="$nav.push('/usdsm/history')">History >></el-link>
                </el-menu>
                <div v-show="usdmsActiveTabs === 'openorders'" class="px-20">
                    <div v-if="openOrderList.length" class="" v-loading="orderLoading">
                        <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 font-14">
                            <el-col :span='4'>Symbol</el-col>
                            <el-col :span='3'>Method</el-col>
                            <el-col :span='3'>Long/Short</el-col>
                            <el-col :span='4'>Price</el-col>
                            <el-col :span='4'>Filled / Size</el-col>
                            <el-col :span='3'>Date Time</el-col>
                            <el-col :span='3' class="text-right">Action</el-col>
                        </el-row>
                        <el-row v-for="(item, index) in openOrderList" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-top font-14">
                            <el-col :span='4'>
                                <span>{{ item.pair }} / USDT</span>
                                <span class="ml-5 color-gray">Perp</span>
                            </el-col>
                            <el-col :span='3'>
                                <el-tag v-if="item.type" type="warning" size="small" effect="plain" hit>Close</el-tag>
                                <el-tag v-else type="primary" size="small" effect="plain" hit>Open</el-tag>
                            </el-col>
                            <el-col :span='3'>
                                <el-tag v-if="item.way" type="danger" size="small" effect="plain" hit>Short</el-tag>
                                <el-tag v-else type="success" size="small" effect="plain" hit>Long</el-tag>
                            </el-col>
                            <el-col :span='4'>{{ item.price }}</el-col>
                            <el-col :span='4'>{{ item.filled_amount }} / {{ item.amount }} {{ item.pair }}</el-col>
                            <el-col :span='3'>{{ item.create_time }}</el-col>
                            <el-col :span='3' class="text-right">
                                <el-button type="danger" size="small" plain class="bg-t" @click="usdsmOnCancel(index)">Cancel</el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <el-empty v-else :image-size="100" description="No Data"></el-empty>
                </div>
                <div v-show="usdmsActiveTabs === 'positions'" class="px-20">
                    <div v-if="positions.length" class="" v-loading="orderLoading">
                        <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 font-14">
                            <el-col :span='3'>Symbol</el-col>
                            <el-col :span='2'>Long/Short</el-col>
                            <el-col :span='3'>Margin Mode</el-col>
                            <el-col :span='3'>Size</el-col>
                            <el-col :span='3'>PNL</el-col>
                            <el-col :span='2'>Margin</el-col>
                            <el-col :span='2'>Entry Price</el-col>
                            <el-col :span='2'>Mark Price</el-col>
                            <el-col :span='2'>Liq.Price</el-col>
                            <el-col :span='2' class="text-right">Action</el-col>
                        </el-row>
                        <el-row v-for="(item, index) in positions" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-top font-14">
                            <el-col :span='3'>
                                <span>{{ item.pair }} / USDT</span>
                                <span class="ml-5 color-gray">Perp</span>
                            </el-col>
                            <el-col :span='2'>
                                <el-tag v-if="item.way" type="danger" size="small" effect="plain" hit>Short</el-tag>
                                <el-tag v-else type="success" size="small" effect="plain" hit>Long</el-tag>
                            </el-col>
                            <el-col :span='3'>
                                <span v-if="item.margin_mode">Isolated</span>
                                <span v-else>Cross</span>
                                <span class="ml-5">{{ item.leverage }}X</span>
                            </el-col>
                            <el-col :span='3'>{{ item.size }} {{ item.pair }}</el-col>
                            <el-col :span='3'>
                                <span v-show="item.pnl > 0">+</span>
                                <span class="ml-3">{{ item.pnl }}</span>
                            </el-col>
                            <el-col :span='2'>{{ item.margin }}</el-col>
                            <el-col :span='2'>{{ item.entry_price }}</el-col>
                            <el-col :span='2'>{{ item.mark_price }}</el-col>
                            <el-col :span='2'>{{ item.liq_price }}</el-col>
                            <el-col :span='2' class="text-right">
                                <el-button type="danger" size="small" plain class="bg-t" @click="usdsmOnClose(index)">Close</el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <el-empty v-else :image-size="100" description="No Data"></el-empty>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
import HQChart from 'hqchart'
HQChart.Chart.JSConsole.Chart.Log=()=>{ }
HQChart.Chart.JSConsole.Complier.Log=()=>{ }
//黑色风格
function GetDevicePixelRatio()
{
    return window.devicePixelRatio || 1;
}
const BLACK_STYLE=
{
  BGColor:'rgb(23,26,37)', //背景色
  TooltipBGColor: "rgb(255, 255, 255)", //背景色
  TooltipAlpha: 0.92,                  //透明度

  SelectRectBGColor: "rgba(1,130,212,0.06)", //背景色
  //  SelectRectAlpha: 0.06;                  //透明度

  //K线颜色
  UpBarColor: "rgb(25,158,0)",   //上涨
  DownBarColor: "rgb(238,21,21)",  //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  Minute: 
  {
      VolBarColor: "rgb(255,236,0)",	//分时图成交量柱子颜色（已经不用了 使用红绿柱)
      PriceColor: "rgb(25,180,231)",	//分时图价格线颜色
      AreaPriceColor:"rgba(63,158,255,.3)",	//价格的面积图
      AvPriceColor: "rgb(255,236,0)",	//分时图均价线颜色
      PositionColor:'rgb(218,165,32)', 	//持仓量线段颜色
  },

  DefaultTextColor: "rgb(255,255,255)",
  DefaultTextFont: 14*GetDevicePixelRatio() +'px 微软雅黑',
  TitleFont: 13*GetDevicePixelRatio() +'px 微软雅黑',    //标题字体(动态标题 K线及指标的动态信息字体)
	
  //K线信息文字
  DownTextColor: "rgb(238,21,21)",	//下跌
  UpTextColor: "rgb(25,158,0)",	//上涨
  UnchagneTextColor: "rgb(101,104,112)",//平盘
  CloseLineColor: 'rgb(178,34,34)',	//收盘价线

  FrameBorderPen: "rgba(236,236,236,0.13)",     //边框
  FrameSplitPen: "rgba(236,236,236,0.13)",          //分割线
  FrameSplitTextColor: "rgb(101,104,112)",     //刻度文字颜色
  FrameSplitTextFont: 12*GetDevicePixelRatio() +"px 微软雅黑",        //坐标刻度文字字体
  FrameTitleBGColor: "rgb(31,38,54)",      //标题栏背景色
  
  Frame:{ XBottomOffset:1*GetDevicePixelRatio() },   //X轴文字向下偏移

  CorssCursorBGColor: "rgb(43,54,69)",            //十字光标背景
  CorssCursorTextColor: "rgb(255,255,255)",
  CorssCursorTextFont: 12*GetDevicePixelRatio() +"px 微软雅黑",
  CorssCursorPenColor: "rgb(130,130,130)",           //十字光标线段颜色

  KLine:
  {
    MaxMin: { Font: 12*GetDevicePixelRatio() +'px 微软雅黑', Color: 'rgb(111,111,111)' },   //K线最大最小值显示
    Info:  //信息地雷
    {
      Color: 'rgb(205,149,12)',
      TextColor: '#afc0da',
      TextBGColor: '#1a283e',
    }
  },

  Index: 
  {      
    LineColor:  //指标线段颜色
    [
      "rgb(255,189,09)",
      "rgb(22,198,255)",
      "rgb(174,35,161)",
      "rgb(236,105,65)",
      "rgb(68,114,196)",
      "rgb(229,0,79)",
      "rgb(0,128,255)",
      "rgb(252,96,154)",
      "rgb(42,230,215)",
      "rgb(24,71,178)",

    ],
    NotSupport: { Font: "14px 微软雅黑", TextColor: "rgb(52,52,52)" }
  },
    
  ColorArray:       //自定义指标默认颜色
  [
    "rgb(255,174,0)",
    "rgb(25,199,255)",
    "rgb(175,95,162)",
    "rgb(236,105,65)",
    "rgb(68,114,196)",
    "rgb(229,0,79)",
    "rgb(0,128,255)",
    "rgb(252,96,154)",
    "rgb(42,230,215)",
    "rgb(24,71,178)",
  ],

  DrawPicture:  //画图工具
  {
    LineColor: "rgb(30,144,255)",
    PointColor: "rgb(105,105,105)",
  }
    
};
const LIGHT_STYLE =
{

  //K线颜色
  UpBarColor: "rgb(25,158,0)",   //上涨
  DownBarColor: "rgb(238,21,21)",  //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘
	
  //K线信息文字
  DownTextColor: "rgb(238,21,21)",	//下跌
  UpTextColor: "rgb(25,158,0)",	//上涨
  UnchagneTextColor: "rgb(101,104,112)",//平盘
  CloseLineColor: 'rgb(178,34,34)',	//收盘价线
    
};

const STYLE_TYPE_ID=
{
    LIGTH_ID:0, //黑色风格
    BLACK_ID:1, //黑色风格
}

function HQChartStyle(){}

HQChartStyle.GetStyleConfig=function(styleid)    //获取一个风格的配置变量
{
  switch (styleid)
  {
      case STYLE_TYPE_ID.LIGHT_ID:
          return LIGHT_STYLE;
      case STYLE_TYPE_ID.BLACK_ID:
          return BLACK_STYLE;
      default:
          return null;
  }
}
let Period = 0
export default {
    data() {
        return {
            coinLogo,
            info: {
                close: null,
                up: false,
                usd: '0.00',
                change: 0,
                accuracy: 2,
                high: '0.00',
                low: '0.00',
                vol: 0
            },
            Decimal: null,
            Period: 0,
            KLine: {
                JSChart: null,
                Option: {
                    Type: '历史K线图', 
                    Language: this.$store.state.locale == 'zh-Hant' ? 'TC' : 'EN',
                    SplashTitle: this.$store.state.locale == 'zh-Hant' ? '数据加载中' : 'Loading',
                    IsShowRightMenu: false,
                    IsAutoUpdate: false,
                    IsApiPeriod: true,
                    IsShowMaxMinPrice: true,
                    IsShowCorssCursorInfo: true,
                    Windows:[
                        {
                            Index:"MA",
                            Modify: true,
                            Change: true
                        },
                        {
                            Index:"VOL",
                            Modify: true,
                            Change: true
                        }
                    ], 
                    Border: {
                        Left:   0,
                        Right:  0, //右边间距
                        Top:    25,
                        Bottom: 25,
                    },
                    KLine:
                    {
                        Right:1,                            //复权 0 不复权 1 前复权 2 后复权
                        Period:0,                           //周期: 0 日线 1 周线 2 月线 3 年线 
                        PageSize:70,
                        IsShowTooltip:true
                    },
                    KLineTitle: {
                        IsShowName: false,
                        IsShowSettingInfo: false
                    }
                },
            },
            mbp: {
                bids: [[0,0], [0,0], [0,0], [0,0], [0,0]],
                asks: [[0,0], [0,0], [0,0], [0,0], [0,0]],
                asks_rate: 50,
            },
            klineLoading: true,
            loading: false,
            dataVersion: 0,
            timer: null,
            // klineSrc: null,
            showMarket: false,
            market: [],
            marketLoading: false,
            marketTimer: null,
            marketDataVersion: 0,
            //
            activeTabs: this.$store.state.tradeActiveTabs,
            tabStyle: {
                spot: {
                    paneStyle: {
                        minHeight: '380px'
                    },
                    mbpStyle: {
                        minHeight: '0px'
                    },
                    mbpAsksRand: [0,1,2,3,4,5,6,7,8,9],
                    mbpBidsRand: [9,8,7,6,5,4,3,2,1,0]
                },
                options: {
                    paneStyle: {
                        minHeight: '200px'
                    },
                    mbpStyle: {
                        minHeight: '754px'
                    },
                    mbpAsksRand: [0,1,2,3,4,5,6],
                    mbpBidsRand: [6,5,4,3,2,1,0]
                },
                usdsm: {
                    paneStyle: {
                        minHeight: '0px'
                    },
                    mbpStyle: {
                        minHeight: '968.5px'
                    },
                    mbpAsksRand: [0,1,2,3,4,5,6,7,8,9],
                    mbpBidsRand: [9,8,7,6,5,4,3,2,1,0]
                }
            },
            userInfo: {
                pair: '--',
                coin: '--',
            },
            buyLoading: false,
            buyFormData: {
                pair: this.$route.params.pair,
                coin: 'USDT',
                price: null,
                amount: null,
                slider: 0,
                pay_password: null
            },
            sellLoading: false,
            sellFormData: {
                pair: this.$route.params.pair,
                coin: 'USDT',
                price: null,
                amount: null,
                slider: 0,
                pay_password: null
            },
            tradingLoading: false,
            tradingList: [],
            rules: {
                price: [
                    { required: true, trigger: 'change' }
                ],
                amount: [
                    { required: true, trigger: 'change' }
                ]
            },
            orderLoading: false,
            optionsOrderShow: false,
            rate: 0,
            lastDueTime: 30,
            dueTimer: null,
            dueTimeList: {
                30: {
                    profit_rate: '75.00',
                    amount_min: '10.00'
                },
                60: {
                    profit_rate: '80.00',
                    amount_min: '300.00'
                },
                80: {
                    profit_rate: '85.00',
                    amount_min: '1000.00'
                },
                120: {
                    profit_rate: '90.00',
                    amount_min: '2000.00'
                },
                180: {
                    profit_rate: '100.00',
                    amount_min: '5000.00'
                }
            },
            orderFormData: {
                pair: this.$route.params.pair,
                due_sec: 0,
                type: 0,
                amount: null,
                profit: '0',
                id: 0,
                status: 0
            },
            optionsOrders: [],
            showMarginModeSetting: false,
            showAdjutLeverageSetting: false,
            usdsmSettingLoading: false,
            leverage: 5,
            usdsmInfo: {
                leverage_max: 100,
                pair_long_balance: '0.00',
                pair_short_balance: '0.00',
                usdt_available: '0.00',
                margin_mode: 0,
                leverage: 5,
            },
            usdsmOpenFormData: {
                type: 0,
                pair: this.$route.params.pair,
                price_type: 0,
                price: '',
                amount: '',
                slider: 0
            },
            usdsmCloseFormData: {
                type: 1,
                pair: this.$route.params.pair,
                price_type: 0,
                price: '',
                amount: '',
                slider: 0
            },
            usdmsActiveTabs: 'openorders',
            usdsmLoadedOrder: false,
            usdsmOrderLoadCount: 1,
            openOrderList: [
                // {
                // 	id: 0,
                // 	type: 1,
                // 	way: 0,
                // 	amount: '1.00',
                // 	filled_amount: '0.000',
                // 	price_type: 0,
                // 	price: '68024.24',
                // 	pair: 'BTC',
                // 	create_time: '2022-14-05 22:52:32'
                // }
            ],
            showCloseFrom: false,
            closeFormData: {
                type: 1,
                pair: 'BTC',
                way: 0,
                margin_mode: 0,
                amount: null,
                slider: 100,
                price_type: 0,
                price: null,
                leverage: 5,
                size: 0,
                mark_price: null,
                pnl: '0',
                roi: '0'
            },
            positions: [
                // {
                // 	id: 1,
                // 	way: 1,
                // 	pair: 'ETH',
                // 	margin_mode: 0,
                // 	leverage: 5,
                // 	pnl: '-1000.00',
                // 	roi: '-79.24',
                // 	size: '4.000',
                // 	margin: '2342.02',
                // 	margin_ratio: '0.90',
                // 	entry_price: '3392.35',
                // 	mark_price: '2932.44',
                // 	liq_price: '1643.36'
                // }
            ]
        }
    },
    computed: {
        buyTotalValue: function () {
            if(!this.buyFormData.price || !this.buyFormData.amount) return ''
            return (this.buyFormData.price * this.buyFormData.amount).toFixed(8) * 1
        },
        sellTotalValue: function () {
            if(!this.sellFormData.price || !this.sellFormData.amount) return ''
            return (this.sellFormData.price * this.sellFormData.amount).toFixed(8) * 1
        },
        expectedProfits: function(){
            if(!this.orderFormData.amount || !this.orderFormData.due_sec) return '0'
            return this.orderFormData.amount * (this.dueTimeList[this.orderFormData.due_sec].profit_rate / 100 + 1)
        },
        currentRate() {
            if(this.lastDueTime == 0 || this.orderFormData.due_sec == 0) return 0
            if(this.lastDueTime >= this.orderFormData.due_sec) return 100
            const num = (this.lastDueTime / this.orderFormData.due_sec).toFixed(2).valueOf() * 100
            return num
        },
        maxOpen: function(){
            if(!this.usdsmInfo.usdt_available || this.usdsmInfo.usdt_available <= 0) return '0.00'
            if(!this.usdsmOpenFormData.price || this.usdsmOpenFormData.price <= 0) return '--'
            return (this.usdsmInfo.usdt_available * this.usdsmInfo.leverage / this.usdsmOpenFormData.price).toPrecision(4) * 1
        }
    },
    watch: {
        $route: function () {
            this.showMarket = false
            this.fatchMarketInfo()
            this.klineLoading = true
            this.onChangeTabs()
            this.buyFormData.pair = this.$route.params.pair
            this.buyFormData.price = null
            this.sellFormData.pair = this.$route.params.pair
            this.sellFormData.price = null
            this.orderFormData.pair = this.$route.params.pair
            this.orderFormData.amount = null
            this.usdsmOpenFormData.pair = this.$route.params.pair
            this.usdsmOpenFormData.amount = null
            this.usdsmCloseFormData.pair = this.$route.params.pair
            this.usdsmCloseFormData.amount = null
            this.CreateKLineChart()
        }
    },
    created: function () {
        this.fatchMarketInfo()
        this.usdsmFetchOrder(true)
        this.timer = window.setInterval(() => {
            if (document.hidden || this.showMarket) return
            this.fatchMarketInfo()
            this.usdsmFetchOrder()
        }, 987)
        this.fatchMarket()
        this.marketTimer = window.setInterval(() => {
            if (document.hidden || !this.showMarket) return
            this.fatchMarket()
        }, 1780)
        this.onChangeTabs()
    },
    beforeDestroy: function () {
        window.clearInterval(this.timer)
        window.clearInterval(this.marketTimer)
        this.clearDuethmer()
    },
    mounted: function () {
        Period = 0
        this.Period = 0
        this.CreateKLineChart()
    },
    methods: {
        NetworkFilter: async function (data, callback) {
            // console.log('[NetworkFilter]', data.Name)
            // callback({})
            data.PreventDefault = true;	//设置hqchart不请求数据
            if(data.Name != 'KLineChartContainer::RequestHistoryData' && data.Name != 'KLineChartContainer::ReqeustHistoryMinuteData'){
                return
            }
            try{
                const res = await this.$request.get('market/kline/symbol/'+ data.Request.Data.symbol + '/times/' + this.Period)
                // console.log(res.data)
                if(res.data.decimal !== undefined) this.Decimal = res.data.decimal
                callback(res.data)
                return
            }catch(e){
                console.error('[Error]', e)
            }
            
        },
        GetDefaultDecimal: function(symbol){
            // console.log(symbol)
            if(this.Decimal) return this.Decimal
            if(symbol == 'BTCUSDT' || symbol == 'ETHUSDT' || symbol == 'BNBUSDT' || symbol == 'LTCUSDT' || symbol == 'SOLUSDT') return 2
            if(symbol == 'TRXUSDT' || symbol == 'DOGEUSDT') return 4
            if(symbol == 'SHIBUSDT') return 8
            return 6;
        },
        CreateKLineChart: function () {
            if (this.KLine.JSChart) {
                Period = 0
                this.Period = 0
                // this.KLine.JSChart.ChangePeriod(0)
                this.KLine.JSChart.ChangeSymbol(this.$route.params.pair + 'USDT')
                return
            }
            this.KLine.Option.Symbol = this.$route.params.pair + 'USDT'
            this.KLine.Option.Period = Period
            // const black = HQChart.Chart.HQChartStyle.GetStyleConfig(HQChart.Chart.STYLE_TYPE_ID.BLACK_ID)
            const style = HQChartStyle.GetStyleConfig(STYLE_TYPE_ID.LIGHT_ID);
            // HQChart.Chart.JSChart.SetStyle(black)
            HQChart.Chart.JSChart.SetStyle(style)
            HQChart.Chart.MARKET_SUFFIX_NAME.GetDefaultDecimal = this.GetDefaultDecimal
            HQChart.Chart.JSChart.GetResource().FrameLogo.Text = null
            const chart = HQChart.Chart.JSChart.Init(this.$refs.kline)
            this.KLine.Option.NetworkFilter = this.NetworkFilter
            chart.SetOption(this.KLine.Option)
            this.KLine.JSChart = chart
        },
        ChangePeriod: function (period) {
            Period = period
            this.Period = period
            if (this.KLine.JSChart) {
                this.KLine.JSChart.ChangePeriod(period)
                return
            }
        },
        fatchMarketInfo: function () {
            if (this.loading) return
            this.dataVersion++
            let version = Number(this.dataVersion)
            this.loading = true
            this.$requestNotState.get('market/item/limit/10/pair/'+this.$route.params.pair).then(({ data }) => {
                if(version == this.dataVersion) {
                    if(this.info.close === null){
                        data.info.up = data.info.change >= 0
                    }else if(this.info.close > data.info.close){
						data.info.up = false
					}else if(this.info.close == data.info.close){
						data.info.up = this.info.up
					}else{
						data.info.up = true
					}
                    this.info = data.info
                    this.mbp = data.mbp
                    if(this.usdsmOpenFormData.price_type === 0) this.usdsmOpenFormData.price = this.info.close
                    if(this.usdsmCloseFormData.price_type === 0) this.usdsmCloseFormData.price = this.info.close
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        // onKlineLoad: function (){
        //     this.klineLoading = false
        // },
        fatchMarket: function () {
            if (this.marketLoading) return
            this.marketDataVersion++
            let version = Number(this.marketDataVersion)
            this.marketLoading = true
            this.$requestNotState.get('market').then(({ data }) => {
                if(version == this.marketDataVersion) {
                    this.market = data
                }
            }).catch(() => {
                //
            })
            this.marketLoading = false
        },
        onChangeTabs(tab = null){
            if(tab !== null) this.$store.commit('tradeActiveTabs',tab.name)
            this.fatchUserInfo()
            this.fatchTradingList()
            this.fatchOptionsOrders()
            this.usdsmFetchInfo()
            this.usdsmFetchOrder(true)
        },
        fatchUserInfo: async function() {
            if(!this.$store.state.userToken || (this.activeTabs !== 'spot' && this.activeTabs !== 'options')) return
            const { data } = await this.$request.get('transaction/info/pair/'+this.$route.params.pair)
            this.userInfo.pair = data.pair
            this.userInfo.coin = data.coin
            this.dueTimeList = data.ods
        },
        onSetPrice: function(price) {
            this.buyFormData.price = price
            this.sellFormData.price = price
            this.usdsmCloseFormData.price = price
            this.usdsmOpenFormData.price = price
        },
        submitBuyForm: async function () {
            if(!this.$store.state.userToken){
                this.$message({
                    message: 'You must login first !',
                    type: 'warning'
                })
                this.$nav.push('/user/login')
                return
            }
            try {
                await this.$refs.buyForm.validate()
                this.buyLoading = true
                await this.$request.post('transaction/buy', this.buyFormData)
                this.buyFormData.price = null
                this.buyFormData.amount = null
                this.buyFormData.pay_password = null
                this.buyLoading = false
                this.fatchTradingList()
                this.fatchUserInfo()
            } catch (error) {
                this.buyLoading = false
            }
        },
        submitSellForm: async function () {
            try {
                await this.$refs.sellForm.validate()
                this.sellLoading = true
                await this.$request.post('transaction/sell', this.sellFormData)
                this.sellFormData.price = null
                this.sellFormData.amount = null
                this.sellFormData.pay_password = null
                this.sellLoading = false
                this.fatchTradingList()
                this.fatchUserInfo()
            } catch (error) {
                this.sellLoading = false
            }
        },
        onCancelTradingOrder: async function(index){
            try {
                this.tradingLoading = true
                await this.$request.post('transaction/cancel',this.tradingList[index])
                this.tradingLoading = false
                this.tradingList.splice(index,1)
                this.fatchUserInfo()
            } catch (error) {
                this.tradingLoading = false
            }
        },
        fatchTradingList: async function () {
            if(!this.$store.state.userToken || this.activeTabs !== 'spot') return
            this.tradingLoading = true
            try{
                const { data } = await this.$requestNotState.get('transaction/open_order/pair/' + this.$route.params.pair)
                this.tradingList = data
                // this.loadedOrder = true
            }catch(e){
                //TODO handle the exception
            }
            this.tradingLoading = false
        },
        fatchOptionsOrders: async function () {
            if(!this.$store.state.userToken || this.activeTabs !== 'options') return
            this.tradingLoading = true
            try{
                const { data } = await this.$requestNotState.get('options/order_pc')
                this.optionsOrders = data.data
            }catch(e){
                //TODO handle the exception
            }
            this.tradingLoading = false
        },
        formatOrderProgress() {
            return `${this.lastDueTime}s`;
        },
        fatchOrderItem: function () {
            if(!this.$store.state.userToken || this.activeTabs !== 'options') return
            if(!this.orderFormData.id) return
            this.$request.post('options/item', this.orderFormData).then(({ data }) => {
                if(data.status == 0){
                    window.setTimeout(() => {
                        this.fatchOrderItem()
                    }, 800)
                }else{
                    this.orderFormData.status = data.status
                    this.fatchUserInfo()
                    this.fatchOptionsOrders()
                }
            })
        },
        submitOptionsOrderForm: async function () {
            if(this.dueTimeList[this.orderFormData.due_sec] == undefined){
                this.$message({
                    message: 'Please select an Options Due Time !',
                    type: 'warning'
                })
                return
            }
            try {
                await this.$refs.optionsOrderForm.validate()
                this.orderLoading = true
                const { data } = await this.$request.post('options/submit', this.orderFormData)
                this.orderLoading = false
                this.userInfo.coin = data.balance
                this.lastDueTime = this.orderFormData.due_sec
                this.orderFormData.id = data.order.id
                this.orderFormData.profit = data.order.profit
                this.clearDuethmer()
                this.dueTimer = window.setInterval(() => {
                    if(this.lastDueTime <= 0){
                        window.clearInterval(this.dueTimer)
                        this.fatchOrderItem()
                        return
                    }
                    this.lastDueTime--
                }, 1000)
            } catch (error) {
                this.orderLoading = false
            }
        },
        onOptionsOrderShow: function(type = null){
            if(!this.$store.state.userToken){
                this.$message({
                    message: 'You must login first !',
                    type: 'warning'
                })
                this.$nav.push('/user/login')
                return
            }
            this.clearDuethmer()
            this.optionsOrderShow = true
            this.orderFormData.id = 0
            this.orderFormData.status = 0
            if(type !== null){
                this.orderFormData.type = type
            }
        },
        onOptionsOrderHide: function(){
            this.optionsOrderShow = false
            this.clearDuethmer()
        },
        clearDuethmer: function(){
            if(this.dueTimer === null) return
            window.clearInterval(this.dueTimer)
        },
        marginModeChange: async function(mode){
            if(this.usdsmInfo.margin_mode == mode) {
                this.showMarginModeSetting = false
                return
            }
            this.usdsmSettingLoading = true
            const postData = {
                pair: this.$route.params.pair,
                mode
            }
            try{
                const { data } = await this.$request.post('usdsm/adjust_margin_mode', postData)
                this.usdsmInfo.margin_mode = mode
                this.usdsmInfo.leverage = data.leverage
                this.showMarginModeSetting = false
            }catch(e){
                //TODO handle the exception
            }
            this.usdsmSettingLoading = false
        },
        adjustLeverageOpen: function(){
            this.leverage = this.usdsmInfo.leverage
            this.showAdjutLeverageSetting = true
        },
        adjustLerverageInput: async function(){
            if(!this.leverage || this.leverage == 0 || this.leverage == this.usdsmInfo.leverage) {
                this.showAdjutLeverageSetting = false
                return
            }
            this.usdsmSettingLoading = true
            if(this.leverage > this.usdsmInfo.leverage_max){
                this.leverage = this.usdsmInfo.leverage_max
            }
            const postData = {
                pair: this.$route.params.pair,
                val: Math.floor(this.leverage)
            }
            try{
                await this.$request.post('usdsm/adjust_leverage', postData)
                this.usdsmInfo.leverage =  postData.val
                this.showAdjutLeverageSetting = false
            }catch(e){
                //TODO handle the exception
            }
            this.usdsmSettingLoading = false
        },
        usdsmChangeTabs(index = null){
            this.usdmsActiveTabs = index
        },
        usdsmFetchInfo: async function() {
            if(!this.$store.state.userToken || this.activeTabs !== 'usdsm') return
            try{
                const { data } = await this.$requestNotState.get('usdsm/info/pair/' + this.$route.params.pair)
                Object.assign(this.usdsmInfo, data)
            }catch(e){
                //TODO handle the exception
            }
        },
        usdsmFetchOrder: async function (refresh = false) {
            if(!this.$store.state.userToken || this.activeTabs !== 'usdsm') return
            if(!refresh && this.openOrderList.length <= 0 && this.positions.length <= 0) return
            try{
                const { data } = await this.$request.get('usdsm/index_orders/pair/' + this.$route.params.pair)
                this.openOrderList = data.open_orders
                this.positions = data.positions
                this.usdsmInfo.usdt_available = data.usdt_available
                this.usdsmInfo.pair_long_balance = data.pair_long_balance
                this.usdsmInfo.pair_short_balance = data.pair_short_balance
                if(refresh){
                    if(this.positions.length){
                        this.usdmsActiveTabs = 'positions'
                    }else if(this.openOrderList.length){
                        this.usdmsActiveTabs = 'openorders'
                    } 
                }
                // this.loadedOrder = true
            }catch(e){
                //TODO handle the exception
            }
        },
        usdsmOnClose: function(index){
            Object.assign(this.closeFormData, this.positions[index])
            this.closeFormData.price = this.closeFormData.mark_price
            this.closeFormData.slider = 100
            this.closeFormData.price_type = 0
            this.closeFormData.amount = this.closeFormData.size
            this.showCloseFrom = true
        },
        usdsmOnSubmitClose: async function () {
            await this.$refs.closeForm.validate()
            this.sellLoading = true
            this.closeFormData.type = 1
            try{
                await this.$request.post('usdsm/open_order', this.closeFormData)
                this.closeFormData.amount = null
                this.showCloseFrom = false
                this.usdsmFetchOrder(true)
            }catch(e){
                // console.log(e)
            }
            this.sellLoading = false
        },
        usdsmOnCancel: async function(index){
            this.orderLoading = true
            try {
                await this.$request.get('usdsm/cancel/id/' + this.openOrderList[index].id)
                this.openOrderList.splice(index,1)
            } catch (error) {
                // console.log(e)
            }
            this.orderLoading = false
        },
        usdsmSubmitOpenForm: async function(way){
            this.buyLoading = true
            this.usdsmOpenFormData.way = way
            this.usdsmOpenFormData.pair = this.$route.params.pair
            try{
                await this.$request.post('usdsm/open_order', this.usdsmOpenFormData)
                this.usdsmOpenFormData.amount = null
                this.usdsmOpenFormData.slider = 0
                this.usdsmFetchOrder(true)
            }catch(e){
                // console.log(e)
            }
            this.buyLoading = false
        },
        usdsmSubmitCloseForm: async function(way){
            this.sellLoading = true
            this.usdsmCloseFormData.way = way
            this.usdsmCloseFormData.pair = this.$route.params.pair
            try{
                await this.$request.post('usdsm/open_order', this.usdsmCloseFormData)
                this.usdsmCloseFormData.amount = null
                this.usdsmCloseFormData.slider = 0
                this.usdsmFetchOrder(true)
            }catch(e){
                // console.log(e)
            }
            this.sellLoading = false
        }
    }
}
</script>
<style>
.white-form input{
    color:black !important;
}
</style>
<style lang="scss" scoped>
.notice-bar{
    .link{
        cursor: pointer;
        display: inline-block;
        white-space: nowrap;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.top-info{
    .pair-coin{
        font-size: 24px;
    }
}
.kline-box{
    position: relative;
    .kline-tools{
        position: absolute;
        top: 45px;
        right: 20px;
    }
    .kline-div{
        height: 480px;
        border: none;
        width: 853px;
    }
}
.tabs{
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $bgColor;
    .tab{
        padding-bottom: 18px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    .active{
        color: $--color-primary;
        border-bottom: 2px solid $--color-primary;
    }
}
.mbp-buy{
    background-color: rgba(255,59,59,0.07);
}
.mbp-sell{
    background-color: rgba(41,194,120,0.07);
}
</style>